<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" :model="searchForm" >
                <el-form-item label="地区名称" prop="name">
                    <el-cascader :props="props" clearable v-model="searchForm.areaId"></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryAreaInfo">查询</el-button>
                    <!--<el-button type="primary" @click="">新增</el-button>-->
                </el-form-item>
            </el-form>
            <el-table :data="tableData" style="width: 100%;" row-key="areaId" size="small" stripe
                border  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                lazy :load="loadTableTreeData" v-loading="listLoading"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" >
                <el-table-column prop="districtCn"  label="区域名称" sortable></el-table-column>
                <el-table-column prop="areaId"  label="区域编码" sortable></el-table-column>
                <el-table-column prop="latitude" label="经度"></el-table-column>
                <el-table-column prop="longitude" label="纬度"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import NavBreadcrumb from '../../components/NavBreadcrumb';
    import { queryProvInfo, queryCityInfo, queryDistrictInfo, queryCascaderAreaInfo, queryAreaInfoByAreaId } from '../../api/sys/areaApi';

    export default {

        name: "AreaManage",
        components: {

            NavBreadcrumb
        },
        data:function() {

            return {

                props: {

                    lazy: true,
                    checkStrictly: true,
                    lazyLoad (node, resolve) {

                        let params = {

                            level: node.level,
                            areaId: node.value
                        };
                        queryCascaderAreaInfo(params).then((res) => {

                            resolve(res.data.list);
                        }).catch(err => console.error(err));
                    }
                },
                listLoading:false,
                searchForm: {

                    areaId: ''
                },
                tableData: []
            }
        },
        methods: {

            queryAreaInfo: function() {

                this.listLoading = true;
                if (this.searchForm.areaId == "") {

                    queryProvInfo().then((res) => {

                        this.tableData = res.data.list;
                        this.listLoading = false;
                    }).catch(err => console.error(err));
                } else {

                    let params = {

                        areaIds: this.searchForm.areaId.join(",")
                    };
                    queryAreaInfoByAreaId(params).then((res) => {

                        this.tableData = res.data.list;
                        this.listLoading = false;
                    }).catch(err => console.error(err));
                }
            },
            loadTableTreeData: function(row, treeNode, resolve) {

                this.listLoading = true;
                if (treeNode.level === 0) {

                    let params = {

                        areaId: row.areaId
                    };
                    queryCityInfo(params).then((res) => {

                        resolve(res.data.list);
                        this.listLoading = false;
                    }).catch(err => console.error(err));
                } else {

                    let params = {

                        areaId: row.areaId
                    };
                    queryDistrictInfo(params).then((res) => {

                        resolve(res.data.list);
                        this.listLoading = false;
                    }).catch(err => console.error(err));
                }
            },
            initPage: function() {

                this.listLoading = true;
                queryProvInfo().then((res) => {

                    this.tableData = res.data.list;
                    this.listLoading = false;
                }).catch(err => console.error(err));
            }
        },
        mounted() {

            this.initPage();
        }
    }
</script>

<style scoped>

</style>
