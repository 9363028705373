import { render, staticRenderFns } from "./AreaManage.vue?vue&type=template&id=7ab93412&scoped=true&"
import script from "./AreaManage.vue?vue&type=script&lang=js&"
export * from "./AreaManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ab93412",
  null
  
)

export default component.exports