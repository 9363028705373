import axios from 'axios';
import global from '../../components/Global';


let base = global.serverBase;

export const queryProvInfo = () => { return axios.post(base + '/areaManage/queryProvInfo')};

export const queryCityInfo = params => {

    return axios({

        url: base + '/areaManage/queryCityInfo',
        method: 'GET',
        data: params
    });
};

export const queryDistrictInfo = params => {

    return axios({

        url: base + '/areaManage/queryDistrictInfo',
        method: 'GET',
        data: params
    });
};

export const queryCascaderAreaInfo = params => {

    return axios({

        url: base + '/areaManage/queryCascaderAreaInfo',
        method: 'GET',
        data: params
    });
};

export const queryAreaInfoByAreaId = params => {

    return axios({

        url: base + '/areaManage/queryAreaInfoByAreaId',
        method: 'GET',
        data: params
    });
};

export const queryAllAreaInfo = params => { return axios.post(base + '/areaManage/queryAllAreaInfo', params)};

export const queryCascaderAreaInfoByOrgId = params => {

    return axios({

        url: base + '/areaManage/queryCascaderAreaInfoByOrgId',
        method: 'GET',
        data: params
    });
};
